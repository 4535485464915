import "./style.scss";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { closeModal, openModal } from "../../components/customModal/modalSlice";
import { openDialog } from "../../components/customDialog/dialogSlice";
import { useCancelOrderMutation, useGetOrdersQuery } from "../DispatchManagement/dispatchApi";
import { useDispatch } from "react-redux";
import { formatMoney } from "../../utils/utils";
import {
  PER_PAGE,
  SEARCH_ORDER,
  STATUS_ARRIVING,
  STATUS_WAITING_LOADING,
  STATUS_DELIVERING,
  STATUS_WAITING_COMPLETION,
  STATUS_WAITING_MATCHING,
  STATUS_DELIVERED,
  STATUS_WAITING_COMEBACK,
  STATUS_WAITING_APPROVAL,
  STATUS_DONE,
} from "../../utils/constants";
import { ShippingInfo } from "../../components/orderInfo/shippingInfo";
import { CarrierFreightCost } from "../../components/orderInfo/carrierFreightCost";
import { ShipperPaymentAmount } from "../../components/orderInfo/ShipperPaymentAmount";
import TransportMethodBadge from "../../components/transportMethod";
import { convertToLocalTimezone, getUrlParams } from "../../utils/commonFnc";
import NewTable from "../../components/NewTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetOrderDetailQuery } from "../SettlementManagement/settlementApi";
import { CancelDispatch } from "../../components/orderInfo/cancelDispatch";
import cashBadge from "../../assets/images/cashBadge.png";

interface DataType {
  id: string;
  matchingDate: string;
  shipperName: string;
  companyName: string;
  carrierName: string;
  loadingPlace: string;
  receiptPlace: string;
  freightCost: string;
  transitTime: string;
  expectedStartDate: string;
  expectedEndDate: string;
  actualStartDate: string;
  actualEndDate: string;
  isCash: boolean;
}

const SEARCH_OR_STATUS = `&searchOr={"status": "${STATUS_WAITING_APPROVAL}"}&searchOr={"status": "${STATUS_ARRIVING}"}&searchOr={"status": "${STATUS_WAITING_LOADING}"}&searchOr={"status": "${STATUS_DELIVERING}"}&searchOr={"status": "${STATUS_WAITING_COMPLETION}"}&searchOr={"status": "${STATUS_WAITING_COMEBACK}"}&searchOr={"status": "${STATUS_DELIVERED}"}&searchOr={"status": "${STATUS_DONE}"}`;

export default function MatchingManagement() {
  const dispatch = useDispatch<any>();
  const [cancelOrder] = useCancelOrderMutation();
  const [allData, setAllData] = useState<any[]>([]);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const idDispatch = getUrlParams("id");
  const [isShowDetail, setIsShowDetail] = useState(!!idDispatch);
  const location = useLocation();
  const state = location.state;

  const [infoDetail, setInfoDetail] = useState<any>({});
  const { data: infoData, refetch: handleRefreshDetail } = useGetOrderDetailQuery(
    { id: idDispatch },
    {
      refetchOnMountOrArgChange: true,
      skip: !idDispatch,
    },
  );

  useEffect(() => {
    setInfoDetail(infoData);
  }, [infoData])

  console.log(infoDetail)


  useEffect(() => {
    if (!!idDispatch) {
      setIsShowDetail(true);
    } else {
      setIsShowDetail(false);
      window.scrollTo(0, 0);
    }

    return () => { };
  }, [location]);

  const [metaData, setMetaData] = useState<any>({});
  const [filters, setFilters] = useState<string[]>(["providerusername"]);
  const [params, setParams] = useState({
    search: "",
    page: 1,
    limit: PER_PAGE,
    startDate: null,
    status: "all",
    endDate: null,
  });

  const REQUEST_PARAMS = useMemo(() => {
    let query = `page=${params.page}&limit=${PER_PAGE}&${SEARCH_ORDER}`;
    if (params.search) {
      query += `&keyword=${params.search}`;
      const providerusernameIndex = filters?.indexOf("providerusername");

      const allFilters =
        providerusernameIndex !== -1
          ? ["representationName", "providerusername", ...filters?.filter((f) => f !== "providerusername")]
          : filters;

      query += allFilters.map((e) => `&searchColumn[]=${e}`).join("");
    }
    if (params.status === "all") {
      query += SEARCH_OR_STATUS;
    } else if (params.status === "LOADING") {
      query += `&searchOr={"status": "${STATUS_ARRIVING}"}&searchOr={"status": "${STATUS_WAITING_LOADING}"}&searchOr={"status": "${STATUS_WAITING_APPROVAL}"}`;
    } else if (params.status === "MOVING") {
      query += `&searchOr={"status": "${STATUS_DELIVERING}"}&searchOr={"status": "${STATUS_WAITING_COMEBACK}"}&searchOr={"status": "${STATUS_WAITING_COMPLETION}"}`;
    } else if (params.status === "COMPLETED") {
      query += `&searchOr={"status": "${STATUS_DELIVERED}"}&searchOr={"status": "${STATUS_DONE}"}`;
    }
    if (params.startDate) {
      query += `&from=${encodeURIComponent(convertToLocalTimezone(params.startDate))}`;
    }
    if (params.endDate) {
      query += `&to=${encodeURIComponent(convertToLocalTimezone(params.endDate, true))}`;
    }
    if (params.startDate || params.endDate) {
      query += `&fieldSearchTime=matchingAt`;
    }
    return query;
  }, [params, filters]);

  const { data, error, isSuccess, isLoading, isFetching } = useGetOrdersQuery(REQUEST_PARAMS, {
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  const mappingStatus = (key: string, record: any) => {
    if (record?.isCompensation) {
      return <span className="badge-status-red">배차취소</span>;
    } else if ([STATUS_ARRIVING, STATUS_WAITING_LOADING].includes(key)) {
      return "상차 이전";
    } else if ([STATUS_DELIVERING, STATUS_WAITING_COMEBACK, STATUS_WAITING_COMPLETION].includes(key)) {
      return "이동중";
    } else if ([STATUS_DELIVERED, STATUS_DONE].includes(key)) {
      return "운송완료";
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("transport_method"),
      dataIndex: "orderType",
      key: "orderType",
      render: (orderType, record) => {
        return (
          <>
            <TransportMethodBadge orderType={orderType} />
            {
              record.isCash &&
              <img src={cashBadge} width={60} alt="선착불 이미지" />
            }
          </>
        );
      },
    },
    {
      title: t("matching_date"),
      dataIndex: "matchingDate",
      key: "matchingDate",
    },
    {
      title: '상차 예정일자',
      dataIndex: "expectedStartDate",
      key: "expectedStartDate",
      render: (_, record) => record?.expectedStartDate.split(' ')[0]
    },
    {
      title: '하차 예정일자',
      dataIndex: "expectedEndDate",
      key: "expectedEndDate",
      render: (_, record) => record?.expectedEndDate.split(' ')[0]
    },
    {
      title: "대표자성명/이름",
      dataIndex: "shipperName",
      key: "shipperName",
      width: 130,
    },
    {
      title: t("company_name"),
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "운송인 이름",
      dataIndex: "carrierName",
      key: "carrierName",
    },
    {
      title: t("loading_place"),
      dataIndex: "loadingPlace",
      key: "loadingPlace",
    },
    {
      title: t("unloading_place"),
      dataIndex: "receiptPlace",
      key: "receiptPlace",
    },
    {
      title: "운송상태",
      dataIndex: "status",
      key: "status",
      render: (item, record) => mappingStatus(item, record),
    },
    {
      title: t("freight_cost"),
      dataIndex: "freightCost",
      key: "freightCost",
      render: (freightCost) => `${formatMoney(freightCost)}${t("won")}`,
    },
    {
      title: t("more_information"),
      dataIndex: "id",
      key: "id",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (_, item) => (
        <a
          className="more-information"
          onClick={() => {
            navigate("/matching-management?id=" + item?.id);
          }}
        >
          {t("more_information")}
        </a>
      ),
    },
  ];

  /* --------------------------------- 필터링 선택 --------------------------------- */
  const searchFilterOptions = [
    { label: "대표자성명/이름", value: "providerusername" },
    { label: t("company_name"), value: "company" },
    { label: "운송인 닉네임", value: "carriernickname" },
    { label: t("loading_place"), value: "loadingPlace" },
    { label: t("unloading_place"), value: "receiptPlace" },
  ];

  useEffect(() => {
    if (isSuccess) {
      const dataMap = data?.data.map((item: any, index: number) => ({
        ...item,
        id: item.id,
        matchingDate: getDateFormat(item?.matchingAt),
        shipperName: item.provider?.representationName ? item.provider?.representationName : "-",
        companyName: item.provider?.company || "-",
        carrierName: item.carrier?.name || "-",
        loadingPlace: item.loadingPlace || "-",
        receiptPlace: item.receiptPlace || "-",
        finalPaymentAmount: getFinalPaymentAmount(item),
        finalFreightCost: getFinalFreightCost(item),
        transitTime: getDurationUpdate(item.actualStartDate, item.actualEndDate, item.estimatedTravelTime),
        expectedStartDate: getDateFormat(item.expectedStartDate, "yyyy.MM.DD A hh시 mm분"), //2022.09.03 오전 11시 30분
        expectedEndDate: getDateFormat(item.expectedEndDate, "yyyy.MM.DD A hh시 mm분"),
        actualStartDate: getDateFormat(item.actualStartDate, "yyyy.MM.DD A hh시 mm분"),
        actualEndDate: getDateFormat(item.actualEndDate, "yyyy.MM.DD A hh시 mm분"),
      }));

      setAllData(dataMap);
      setMetaData(data?.meta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error && "status" in error && Number(error?.status) >= 300) {
      dispatch(
        openDialog({
          type: "info",
          content: t("have_some_error"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  /* ---------------------------------- 시간 빼기 --------------------------------- */
  const subtractTime = (startTimeStr: string, endTimeStr: string) => {
    const startTime = moment(startTimeStr);
    const endTime = moment(endTimeStr);
    var minutes = endTime.diff(startTime, "minutes") || 0;
    if (minutes < 1) {
      return `0${t("minutes")}`;
    } else if (minutes >= 60) {
      var hours = ~~(minutes / 60);
      minutes = minutes % 60;
      return minutes > 0 ? `${hours}${t("hours")} ${minutes}${t("minutes")}` : `${hours}${t("hours")}`;
    }
    return `${minutes}${t("minutes")}`;
  };

  /* --------------------------------- 기간 가져오기 -------------------------------- */
  const getDurationUpdate = (startTimeStr: string, endTimeStr: string, estimatedTravelTime: number) => {
    let second = estimatedTravelTime;
    let hour = Math.floor(second / 3600);
    let min = Math.floor((second % 3600) / 60);
    let duration = `${hour} ${t("hours")} ${min} ${t("minutes")}`;
    if (hour <= 0 && min > 0) {
      duration = `${min} ${t("minutes")}`;
    }

    if (hour <= 0 && min <= 0 && second > 0) {
      duration = `1 ${t("minutes")}`;
    }

    if (hour <= 0 && min <= 0 && second <= 0) {
      duration = subtractTime(startTimeStr, endTimeStr);
    }
    if (hour > 0 && min == 0) {
      duration = `${hour} ${t("hours")}`;
    }

    return duration;
  };

  const getDateFormat = (dateStr: string, format: string = "yyyy.MM.DD") => {
    const date = moment(dateStr);
    if (date.isValid()) {
      return date.format(format).replace("AM", "오전").replace("PM", "오후");
    }
    return "";
  };

  /* ---------------------------------- 오더 취소 --------------------------------- */
  const callCancelOrder = async () => {
    const params = {
      id: infoDetail.id,
    };
    const res = await cancelOrder(params); //@ts-ignore
    const errMsg = res?.error?.data?.message;
    if (!errMsg) {
      dispatch(
        openDialog({
          type: "info",
          content: t("canceled"),
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    } else {
      dispatch(
        openDialog({
          type: "info",
          content: errMsg,
          confirmText: t("dialog_btn_confirm"),
          actionConfirm: () => {
            dispatch(closeModal());
          },
        }),
      );
    }
  };

  // const callUpdateDoneOrder = async () => {
  //   const params = {
  //     id: infoDetail.id,
  //   };
  //   const res = await updateDone(params);//@ts-ignore
  //   const errMsg = res?.error?.data?.message;
  //   if (!errMsg) {
  //     dispatch(
  //       openDialog({
  //         type: "info",
  //         content: t("shipment_complete"),
  //         confirmText: t("dialog_btn_confirm"),
  //         actionConfirm: () => {
  //           dispatch(closeModal());
  //         }
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       openDialog({
  //         type: "info",
  //         content: errMsg,
  //         confirmText: t("dialog_btn_confirm"),
  //         actionConfirm: () => {
  //           dispatch(closeModal());
  //         }
  //       })
  //     );
  //   }
  // };

  const onCancelMatchingSubmit = () => {
    callCancelOrder();
  };

  const onCancelMatching = () => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_cancel_dispatch"),
          dialogContent: t("cancel_dispatch_note"),
        },
        handleAction: () => {
          dispatch(closeModal());
          onCancelMatchingSubmit();
        },
      }),
    );
  };

  // const onDoneForTest = () => {
  //   dispatch(
  //     openModal({
  //       template: "show-confirm-dialog-modal",
  //       width: "468px",
  //       data: {
  //         dialogTitle: t("confirm_finish_dispatch")
  //       },
  //       handleAction: () => {
  //         dispatch(closeModal());
  //         callUpdateDoneOrder();
  //       },
  //     })
  //   );
  // }

  /* -------------------------------- 운임 비용 변경 -------------------------------- */
  // const onFareModification = () => {
  //   dispatch(
  //     openModal({
  //       template: "show-confirm-dialog-modal",
  //       width: "468px",
  //       data: {
  //         dialogTitle: t("confirm_amend_the_fare"),
  //       },
  //       handleAction: () => {
  //         dispatch(
  //           openModal({
  //             template: "show-fare-modification-modal",
  //             width: "530px",
  //             data: infoDetail,
  //             handleAction: () => {
  //               handleRefreshDetail();
  //             },
  //           }),
  //         );
  //       },
  //     }),
  //   );
  // };

  const getFinalPaymentAmount = (infoDetail: any) => {
    const freightCost = ~~infoDetail.freightCost || 0;
    const vat = ~~infoDetail.vat || 0;
    return freightCost + vat;
  };

  const getFinalFreightCost = (infoDetail: any) => {
    const carrierPaymentAmount = ~~infoDetail.carrierPaymentAmount || 0;
    const excludingFee = ~~infoDetail.excludingFee || 0;
    return carrierPaymentAmount - excludingFee;
  };

  const getFeeRate = (infoDetail: any) => {
    const carrierPaymentAmount = ~~infoDetail.carrierPaymentAmount || 0;
    if (carrierPaymentAmount < 100000) {
      return 20;
    } else if (carrierPaymentAmount < 200000) {
      return 15;
    } else if (carrierPaymentAmount < 300000) {
      return 13;
    }
    return 10;
  };

  /* ---------------------------------- 타입 선택 --------------------------------- */
  const typeOptions = useMemo(
    () => [
      { label: "전체", value: "all" },
      { label: "상차이전", value: "LOADING" },
      { label: "이동중", value: "MOVING" },
      { label: "운송완료", value: "COMPLETED" },
    ],
    [],
  );

  let newInfoDetail = {
    ...infoDetail,
    transitTime: getDurationUpdate(
      infoDetail?.actualStartDate,
      infoDetail?.actualEndDate,
      infoDetail?.estimatedTravelTime,
    ),
    expectedStartDate: getDateFormat(infoDetail?.expectedStartDate, "yyyy.MM.DD A hh시 mm분"), //2022.09.03 오전 11시 30분
    expectedEndDate: getDateFormat(infoDetail?.expectedEndDate, "yyyy.MM.DD A hh시 mm분"),
    actualStartDate: infoDetail?.actualStartDate
      ? getDateFormat(infoDetail?.actualStartDate, "yyyy.MM.DD A hh시 mm분")
      : "",
    actualEndDate: infoDetail?.actualEndDate ? getDateFormat(infoDetail?.actualEndDate, "yyyy.MM.DD A hh시 mm분") : "",
  };

  useEffect(() => {
    newInfoDetail = {
      ...infoDetail,
      transitTime: getDurationUpdate(
        infoDetail?.actualStartDate,
        infoDetail?.actualEndDate,
        infoDetail?.estimatedTravelTime,
      ),
      expectedStartDate: getDateFormat(infoDetail?.expectedStartDate, "yyyy.MM.DD A hh시 mm분"), //2022.09.03 오전 11시 30분
      expectedEndDate: getDateFormat(infoDetail?.expectedEndDate, "yyyy.MM.DD A hh시 mm분"),
      actualStartDate: infoDetail?.actualStartDate
        ? getDateFormat(infoDetail?.actualStartDate, "yyyy.MM.DD A hh시 mm분")
        : "",
      actualEndDate: infoDetail?.actualEndDate ? getDateFormat(infoDetail?.actualEndDate, "yyyy.MM.DD A hh시 mm분") : "",
    };
  }, [infoDetail])

  const isCancelDispatch = !!newInfoDetail?.isCompensation;

  const openChangeCarrierModal = () => {
    dispatch(
      openModal({
        template: "change-carrier-modal",
        data: { setInfoDetail }
      }),
    );
  }

  const openMemo = () => {
    console.log('infoDetail?.notes', infoDetail?.notes)
    dispatch(
      openModal({
        template: "handle-memo-modal",
        width: "512px",
        data: { notes: infoDetail?.notes, setInfoDetail },
      }),
    );
  }

  const onFareModification = () => {
    dispatch(
      openModal({
        template: "show-confirm-dialog-modal",
        width: "468px",
        data: {
          dialogTitle: t("confirm_amend_the_fare"),
        },
        handleAction: async () => {
          dispatch(
            openModal({
              template: "update-otherfee-modal",
              width: "530px",
              data: infoDetail,
              handleAction: () => {
                handleRefreshDetail();
              },
            }),
          )
        },
      }),
    );
  };

  return (
    <div className="matching-management">
      {!isShowDetail && (
        <NewTable
          title={t("matching_management")}
          columns={columns}
          allData={allData}
          metaData={metaData}
          typeOptions={typeOptions}
          searchFilterOptions={searchFilterOptions}
          loading={isFetching}
          params={params}
          setParams={setParams}
          filters={filters}
          setFilters={setFilters}
          showDateFilter={true}
        />
      )}
      {isShowDetail && (
        <div className="matching-management-detail">
          <div className="title-wrapper">
            <div className="matching-title">{t("matching_details")}</div>
            <div className="dispatch-cost">
              <div className="freight-cost">
                <div className="cost-unit">{t("won")}</div>
                <div className="cost-text">
                  {
                    newInfoDetail?.isCash && newInfoDetail?.otherFee
                      ? formatMoney(newInfoDetail?.freightCost + newInfoDetail?.otherFee)
                      : formatMoney(newInfoDetail?.freightCost)
                  }
                </div>
                <div className="title-text">화주 등록 운임</div>
              </div>
              <div className="freight-cost">
                <div className="cost-unit">{t("won")}</div>
                <div className="cost-text">
                  {
                    formatMoney(newInfoDetail?.carrierPaymentAmount)
                  }
                </div>
                <div className="title-text">운송인 운임</div>
              </div>
            </div>
          </div>
          <div className="change-carrier-wrapper">
            <div className="wrapper-btn fill" onClick={openChangeCarrierModal}>
              <div className="btn-text fill">기사님 변경</div>
            </div>
            <div className="wrapper-btn fill" onClick={openMemo}>
              <div className="btn-text fill">메모</div>
            </div>
          </div>
          <div className="matching-detail-content">
            <div className="shipping-info">
              <ShippingInfo data={newInfoDetail} fromPage="MATCHING_MANAGEMENT" />
            </div>
            {!isCancelDispatch ? (
              <>
                <div className="shipper-payment-amount">
                  <ShipperPaymentAmount data={newInfoDetail} fromPage="MATCHING_MANAGEMENT" />
                </div>
                <div className="freight-cost">
                  <CarrierFreightCost data={newInfoDetail} fromPage="MATCHING_MANAGEMENT" />
                </div>
              </>
            ) : (
              <div className="freight-cost">
                <CancelDispatch data={newInfoDetail} />
              </div>
            )}
          </div>
          <div className="matching-detail-footer">
            {(newInfoDetail?.isCash && !newInfoDetail?.isCompensation) && (
              <div className="wrapper-btn fill" onClick={onFareModification}>
                <div className="btn-text fill">운임 수정</div>
              </div>
            )}
            <div className="wrapper-btn">
              <div
                className="btn-text"
                onClick={() => {
                  if (state?.prev) {
                    navigate(state?.prev, {
                      state: { isPopupOpen: true, id: state?.id, data: state?.data, month: state?.month },
                    });
                  } else {
                    navigate("/matching-management");
                  }
                }}
              >
                {t("list")}
              </div>
            </div>
            {/* <div className="wrapper-btn" onClick={onCancelMatching}>
              <div className="btn-text">{t("dispatch_cancellation")}</div>
            </div> */}
            {/* { infoDetail?.status === "WAITING_PAYMENT" &&
              <div
                className="wrapper-btn"
                onClick={onDoneForTest}
              >
                <div className="btn-text">{t("done_for_test")}</div>
              </div>
            } */}
            {/* {newInfoDetail?.status === STATUS_WAITING_MATCHING && (
              <div className="wrapper-btn" onClick={onFareModification}>
                <div className="btn-text">{t("fare_modification")}</div>
              </div>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
}
